import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Browsernav from '../Components/Browsernav';
import Mobilenav from '../Components/Mobilenav';
import { GlobalContext } from "../Globalstate";
import { useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import Swal from 'sweetalert2'

function RoboticSoftware() {

    const plans = [
        {
            id: 1,
            title: 'NOVICE BOT PLAN🏅',
            range: '3k - 5k',
            duration: '7-10 days active trading sections',
        },
        {
            id: 2,
            title: 'BRONZE BOT PLAN🥉',
            range: '10k - 15k',
            duration: '14-20 days active trading sections',
        },
        {
            id: 3,
            title: 'SILVER BOT PLAN🥈',
            range: '20k - 25k',
            duration: '28-35 days active trading sections',
        },
        {
            id: 4,
            title: 'GOLD BOT PLAN🥇',
            range: '30k - 35k',
            duration: '56-63 days active trading sections',
        },
        {
            id: 5,
            title: 'DIAMOND BOT PLAN 💎🎖️',
            range: '40k - 50k',
            duration: '85-100 days active trading sections',
        },
    ];
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [loading, setloading] = useState(true)
    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    const ActivateRobots = () => {
        Swal.fire({
            title: 'The auto trading bot can only be activated by the bot department, message us Via Live support to activate Robots ',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })


        history("/deposit")
    }
    return (
        <div>
            <Helmet>
                <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <title> ATA-trades Main </title>
                <meta name="description" content="            
														" />

                <link rel="shortcut icon" type="image/png" sizes="32x32" href="assets/img/favicon.png" />
                <link rel="shortcut icon" type="image/png" sizes="16x16" href="assets/img/favicon.png" />
                <link rel="canonical" href="dashboard" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
                <link href="/dashboard/assets/css/css.css" rel="stylesheet" type="text/css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/inner.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/app.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/cs.css" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweetalert.min.js" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweet-alert.css" />
                <script async="" src="//dashboard/assets/js/js.js"></script>
                <script type="text/javascript" src="//dashboard/assets/js/conversion.js"></script>
                <script type="text/javascript" src="/dashboard/assets/js/sweet-alert.js"></script>
                <script type="text/javascript" async="" src="https://ustocktradebits.com/dashboard/assets/js/conversion_async.js"></script>

                <script src="/dashboard/assets/inner.js"></script>
                <script src="/js/app.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/conversion_async.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/watch.js"></script>
                <script async="" src="//dashboard/assets/js/analytics.js"></script>
                <script src="/dashboard/assets/inner.js"></script>
                <script src="/dashboard/assets/js/vendor.js"></script>
                <script src="/dashboard/assets/app.js"></script>

                <script type="text/javascript" src="validation.min.js"></script>
                <script type="text/javascript" src="trade_script.js"></script>
                <script type="text/javascript" src="trade_script2.js"></script>
                <script src="/js/sweet-alert.js"></script>
                <script src="https://use.fontawesome.com/4b789087e7.js"></script>
            </Helmet>
            {/* <Mobilenav />
            <Browsernav/> */}
            <div className="nk-app-root">

                {/* main @s  */}
                <div className="nk-main ">
                    {/* wrap @s  */}
                    <div className="nk-wrap ">
                        {/* main header @s  */}

                        {/* main header @e  */}

                        {/* content @s  */}
                        <div className="nk-content ">
                            <div className="container-fluid">
                                <div className="nk-content-inner">
                                    <div className="nk-content-body">
                                        <div className="card card-bordered card-preview">
                                            {/* <div className="card-inner">
                                                <nav>
                                                    <ul className="breadcrumb breadcrumb-arrow">
                                                        <li className="breadcrumb-item"><a href="https://lucids-fx.com/trade/user/dashboard">Home</a></li>
                                                        <li className="breadcrumb-item active">New Trade</li>
                                                    </ul>
                                                </nav>
                                            </div> */}
                                        </div>{/* .card-preview */}
                                        <br />
                                        <div className="alert alert-icon alert-primary" role="alert">
                                            <em className="icon ni ni-alert-circle" />
                                            <strong>Welcome to the Market! </strong>Here you can purchase various automated trading bots ( ROBOTIC TRADING SOFTWARE ), Which gives you 99% assurance of winning almost all your trades and earn more profit without much stress.
                                        </div>
                                        {/* <img onClick={ActivateRobots} src="https://lucids-fx.com/bot.png" alt="Girl in a jacket" width="auto" height="auto" /> */}






                                        <div className="card-list">
                                            {plans.map((plan) => (
                                                <div className="card" key={plan.id} onClick={ActivateRobots}>
                                                    <h2>{plan.title}</h2>
                                                    <p>
                                                        Range: <span>{plan.range}</span>
                                                    </p>
                                                    <p>
                                                        Duration: <span>{plan.duration}</span>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>

















                                        <div className="pay-tabs-content">
                                            <div className="ui bottom attached tab segment" data-tab="perfect">
                                                <h2 className="title ng-binding" ng-bind="'funding.form_title.perfect' | lang">Deposit using USDT TRC-20:</h2>
                                                <div className="withdraw-form clearfix">
                                                    <div className="btc-amount" style={{ marginBottom: '15px' }}>
                                                        <div>
                                                            <form >
                                                                <span ng-bind="'funding.bitcoin.amount' | lang" className="ng-binding">Amount in base currency:</span>
                                                                <b className="ui green inverted header">
                                                                    <input type="text" className="form-control" style={{ width: '200px', color: '#00FF00', fontWeight: 'bold' }} required />
                                                                    <span ng-bind="$parent.ranger.currency" className="ng-binding">USD</span></b><br /><br />
                                                                <input type="hidden" className="form-control" name="pack_plan" defaultValue="Optional" />
                                                                <span ng-bind="$parent.ranger.currency" className="ng-binding" /><br /><br />

                                                            </form>
                                                            <div className="center-button">

                                                                <button className="ui button primal ng-binding" onClick={ActivateRobots}>Fund account</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
























                                        <br />
                                        <br />
                                        <div className="alert alert-icon alert-primary" role="alert">
                                            <em className="icon ni ni-alert-circle" />
                                            <strong>What to do? </strong>Tap on the live chat icon below and and a customer representative will guild you on how to use and order our automated trading bots ( ROBOTIC TRADING SOFTWARE ).
                                        </div>
                                        <p>All materials and services provided on this site are subject to copyright and belong to "Bitreassure". Any use of materials of this website must be approved by an official representative of "Bitreassure", and contain a link to the original resource. Any third-party companies of "Online broker" or "Online trading" type, do not have the right to use materials of this website as well as any distorted writing of "Bitreassure". In case of violation, they will be prosecuted in accordance with legislation of the intellectual property protection.
                                            Bitreassure does not provide service to residents of Israel.
                                        </p>

                                    </div>{/* .nk-iv-wg4 */}
                                </div>{/* .card */}
                            </div>{/* .col */}
                        </div>{/* .row */}
                    </div>{/* .nk-block */}
                </div>
            </div>
            {/* .card-preview */}
            {/* content @e  */}
            {
                loading && (
                    <div class="preloader js-preloader">
                        <div class="loader loader-inner-1">
                            <div class="loader loader-inner-2">
                                <div class="loader loader-inner-3">
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default RoboticSoftware