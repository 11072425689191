import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import { GlobalContext } from "../Globalstate";

function Mobilenav() {
    const navigate = useNavigate();
    const [{ userdetails, loggedin, tradingpair, openorders, adminstaus }, dispatch] = useContext(GlobalContext);
    function onLinkClick(e) {
        e.preventDefault();
        // further processing happens here
    }

    const logout = async () => {
        const let2 = await f.auth().signOut();
        const let3 = await navigate("/");
    };
    return (
        <>
            <div className="ui sidebar right inverted vertical menu" aria-hidden="false">
                <div className="item" style={{ fontSize: 'small', color: '#FFFFFF', position: 'absolute', top: '15px', textTransform: 'capitalize' }}>
                    <span style={{ marginTop: '10px' }}><b> live: {userdetails.balance}</b> <b>USD</b></span>
                    <br />
                    {/*<span style="margin-top:10px; background:#E95524"><b><i class="fa fa-money"></i> Demo: 0.00</b> <b>USD</b></span>
		*/}
                </div>
                <span className="divider" />
                <div className="item"><Link to="/real" onClick={onLinkClick}> Trading</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/deposit" onClick={onLinkClick}>Funding account</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/withdrawal" onClick={onLinkClick}> Withdrawals</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/demohistory" onClick={onLinkClick}>Demo Trading history</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/roboticsoftware" onClick={onLinkClick}> Robotic Software</Link></div>
                <span className="divider" />
                {/* <div className="item"><Link to="/teslacharging" onClick={onLinkClick}> Tesla Charging</Link></div>
                <span className="divider" /> */}
                <div className="item"><Link to="/transactionhistory" onClick={onLinkClick}> Trading history</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/withdrawalhistory" onClick={onLinkClick}>Withdrwal History</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/deposithistory" onClick={onLinkClick}>Deposit History</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/profile" onClick={onLinkClick}> Personal Data</Link></div>
                <span className="divider" />
                <div className="item"><Link to="/security" onClick={onLinkClick}> Security settings</Link></div>
                {/* <span className="divider" />
                <div className="item"><a href="/contact-us.html" > Support</a></div> */}
                <span className="divider" />
                <div className="item"><Link to="/" onClick={logout}>Exit</Link></div>
                <span className="divider" />
            </div>
        </>
    )
}

export default Mobilenav