import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Browsernav from '../Components/Browsernav';
import Mobilenav from '../Components/Mobilenav';
import { GlobalContext } from "../Globalstate";
import { useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import Swal from 'sweetalert2'
import emailjs from "emailjs-com";
// import ScriptTag from "react-script-tag";

function Teslachargers() {
    const history = useNavigate();
    const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
    const [coinselected, setcoinselected] = useState("btc")
    const [amount, setamount] = useState("")
    const [wallet, setwallet] = useState("")
    const [loading, setloading] = useState(true)

    const updateUserBalance = async (bal) => {
        const newEarings = parseFloat(userdetails.balance) - parseFloat(amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef
            .update({
                balance: newEarings,
            })
            .then(function () {
                console.log("Document successfully up2dated!");
                // alert("withdrawal was successful we will get back to you");
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
        // fetchuserdata();
    };

    const updatewithdrawalhistory = async () => {
        const newBal = parseFloat(userdetails.balance); /*- parseFloat(amount);*/
        const newEarings = parseFloat(userdetails.balance) - parseFloat(amount);
        var userid = f.auth().currentUser;
        var userids = userid.uid;
        const d1 = new Date();
        var washingtonRef = db.collection("users").doc(userids);
        await washingtonRef.update({
            withdrawalhistory: firebase.firestore.FieldValue.arrayUnion({
                amt: amount,
                date: d1.getTime(),
                mode: "withdrawal",
                wallet: wallet,
                coin: coinselected,
                status: "pending"

            }),
        });
    };

    const sendAdminMaiil = async () => {
        var templateParams = {
            message: `${userdetails.email} have successfully withdrawan  $${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  check admin dashboard to verify `,
        };

        // setloading(false)
        //handleClick1()

        await emailjs
            .send(
                "service_zvu1h4k",
                "template_sw3wd5g",
                templateParams,
                "qUOF37s42i9PVN5RY"
            )
            .then(
                function (response) {
                    // setloading(false)
                },
                function (err) {
                    // setloading(false)
                    alert("FAILED...", err);
                }
            );
    }
    const Withdrawal = async (usermail) => {
        let d = new Date()
        let n = d.toString()

        setloading(true)
        await updatewithdrawalhistory()
        await updateUserBalance()
        sendAdminMaiil()

        setloading(false)
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Withdrwal has been Approved',
            showConfirmButton: false,
            timer: 1500
        })

        history("/real");

    };


    function Confirmwithdrawal() {
        if (amount == "") {
            Swal.fire(
                'No Selected Amount?',
                'Please Select Amount To Withdraw?',
            )
            return
        }

        Swal.fire({
            title: 'Confirmation',
            text: `do you want to withdraw ${amount} worth of ${coinselected} to ${wallet}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (parseFloat(userdetails.balance) < parseFloat(amount)) {
                    Swal.fire(
                        'balance not enough',
                        'Please Select Valid Amount?',
                    )
                } else {
                    setloading(true)
                    Withdrawal(userdetails.email)
                }
            }
        })

        // if (!userdetails.verified) {
        //     // alert("upload your documents for verification to complete withdrawal")
        //     return
        // }

    }

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };
    return (
        <div>
            <Helmet>
                <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <title> Elite-protrade.com Main </title>
                <meta name="description" content="            
														" />

                <link rel="shortcut icon" type="image/png" sizes="32x32" href="assets/img/favicon.png" />
                <link rel="shortcut icon" type="image/png" sizes="16x16" href="assets/img/favicon.png" />
                <link rel="canonical" href="dashboard" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
                <link href="/dashboard/assets/css/css.css" rel="stylesheet" type="text/css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/inner.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/app.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/cs.css" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweetalert.min.js" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweet-alert.css" />
                <script async="" src="//dashboard/assets/js/js.js"></script>
                <script type="text/javascript" src="//dashboard/assets/js/conversion.js"></script>
                <script type="text/javascript" src="/dashboard/assets/js/sweet-alert.js"></script>
                {/* <script type="text/javascript" async="" src="https://ustocktradebits.com/dashboard/assets/js/conversion_async.js"></script>

                <script type="text/javascript" async="" src="https://ustocktradebits.com/dashboard/assets/js/conversion_async.js"></script>
                <script type="text/javascript" async="" src="https://ustocktradebits.com/assets/js/conversion_async.js"></script>

                <script type="text/javascript" async="" src="https://ustocktradebits.com/assets/js/watch.js"></script>
                <script async="" src="https://ustocktradebits.com/assets/js/analytics.js"></script>
                <script src="https://ustocktradebits.com/dashboard/assets/inner.js"></script>
                <script src="https://ustocktradebits.com/assets/js/vendor.js"></script> */}
                <script src="/js/app.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/conversion_async.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/watch.js"></script>
                <script async="" src="//dashboard/assets/js/analytics.js"></script>
                <script src="/dreamworld files/inner.min9654.js"></script>
                <script src="/dreamworld files/js/vendor.min9654.js"></script>
                <script src="/dreamworld files/app.min9654.js"></script>

                <script type="text/javascript" src="validation.min.js"></script>
                <script type="text/javascript" src="trade_script.js"></script>
                <script type="text/javascript" src="trade_script2.js"></script>
                <script src="/js/sweet-alert.js"></script>
                <script src="https://use.fontawesome.com/4b789087e7.js"></script>
            </Helmet>
            <Mobilenav />
            <div id="google_translate_element"></div>
            {/* <ScriptTag type="text/javascript">
                    {`

                                  function googleTranslateElementInit() {
                                  new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
                                    }
                                     `}
                </ScriptTag>
                <ScriptTag
                    type="text/javascript"
                    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                ></ScriptTag> */}
            <div className="pusher" aria-hidden="false">
                <nav className="top-nav inner inner-new">
                    <div className="top-bar" style={{ height: '50px' }}>
                        <div className="head-row clearfix">
                            <div className="float-left ">
                                <div className="logosm">
                                    <a href="dashboard.php"><img src="assets/img/toplogo.png" alt="ProvidusOption" /></a>
                                </div>
                                {/*<a href="" class="top-bar-nav dinb"><i class="help-open"></i> <span>Support</span></a>*/}
                                <div className="ui item dropdown dropdown-call dinb" tabIndex={0}>
                                    <div className="top-bar-nav">
                                        <div className="phone-open" />
                                    </div>
                                    <div className="menu transition hidden" tabIndex={-1}>
                                        <div className="item">
                                            <div className="phone-notice">Please input your phone number with<br />country code and we will immediately contact you.</div>
                                            <div>+<input className="call-input" placeholder="phone" type="text" maxLength={20} force-integer id="callback" style={{ width: '200px' }} /><button className="button ui green-bm" id="call_btn" onclick="callBack()">Call back</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="menu-toggle float-right"><i /></span>
                            <div className="float-right nav smcapy-nav">
                                <div className="ui item lang pointing dropdown" tabIndex={0}>
                                    <input type="hidden" name="lang" />
                                    <div className="default text">
                                        <div id="google_translate_element" className="google-trans" />
                                    </div>
                                    <i className="dropdown icon" />
                                </div>
                                <div className="item"><span style={{ color: '#eee' }}>Account Status:</span>
                                    <span style={{ color: '#0f0' }} className="blinkgreen"><i className="fa fa-check-circle" /> Active</span>
                                </div>
                                <div className="item"><a className="ui button op link a-green-hover" href="logout.php"><i className="fa fa-power-off" /> Exit</a></div>
                                <div className="item"><a className="ui button op nobold" style={{ background: '#07b18c' }} href="/deposit"> Account Funding</a></div>
                                {/*<div class="item"><a class="ui button op nobold" style="background:#E95524" href="acct-funding.php"><i class="fa fa-money"></i> Demo Account {userdetails.balance} USD</a></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="top-line clearfix">
                        <div className="row-30">
                            <ul className="icon-nav">
                                <li className="active"><a target="_blank" title="Charting tools" className="technic" href="chart.php" style={{ color: '#FFFFFF' }}><i className="fa fa-bar-chart" /></a></li>
                                <li className="active"><a title="Fundamental analysis" className="fundament" href="fundamental-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-pie-chart" /></a></li>
                                <li className="active"><a title="News" className="expert" href="news.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-tv" /></a></li>
                                <li className="active"><a title="Technical Analysis" className="ideas" href="technical-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-signal" /></a></li>
                                <li className="active"><a title="Economic calendar" className="calendar" href="calendar.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-calendar" /></a></li>
                            </ul>
                        </div>
                        <Browsernav />
                    </div>
                </nav>
                
                <div className="root-content">
                    <div className="pusher push-trading">
                        {/* uiView:  */}<div><div className="pusher pusher-min-400">
                            <section className="img-bg-section">
                                <div className="row">
                                    <ul className="tabs ng-scope" ng-controller="Menu">
                                        {/* <li><a >Account Funding</a></li> */}
                                        <li><a className="active">Tesla Charging Allocation </a></li>
                                    </ul>
                                    <div className="mob-tab-nav mob-main-tabs">
                                        <div className="ui not_select dropdown mob-tabular">
                                            <div className="text default">Tesla Charging Allocation </div>
                                            <i className="dropdown icon" />
                                            <div className="menu">
                                                <a className="item">Account Funding</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="top-info">
                                        <h2 className="title">Your available balance is: <span style={{ color: '#2597c7' }}>{userdetails.balance} USD</span></h2>
                                    </div>
                                    <div align="center"><p className="p" /></div>
                                </div>
                                <span className="blue-arrow" />
                            </section>
                            <div>
                                <section className="content-box wd-area-box">
                                    <div className="row">

                                        <div className="ui bottom attached tab segment active" data-tab="trading-account">
                                            {/* <div className="wd-warn"><b>Important:</b> Chosen withdrawal method should correspond with method used for deposit.</div> */}
                                            <h2 className="title">Select Tesla Charger to proceed</h2>
                                            <div className="pay-tabs-content">

{/* TESLA CHARGERS */}
                                                <main>
                                                    <div className="container-fluid bg-trasparent my-4 p-3" style={{ position: 'relative' }}>
                                                        <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://digitalassets.tesla.com/tesla-contents/image/upload/f_auto,q_auto:best/Main-Charging-Supercharging-Desktop_001" className="card-img-top" alt="..." />
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start badge rounded-pill bg-primary">ASUS Rog</span>
                                                                            <span className="float-end price-hp">12354.00$</span>
                                                                        </div>
                                                                        <h5 className="card-title">Tesla Superchargers are high-speed charging stations strategically located along major highways and travel routes.
          These chargers are designed to provide rapid charging for Tesla vehicles, allowing drivers to quickly recharge
          their batteries during long journeys.</h5>
                                                                        <div className="text-center my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://digitalassets.tesla.com/tesla-contents/image/upload/h_1800,w_2880,c_fit,f_auto,q_auto:best/ChargingMain_HC_D" className="card-img-top" alt="..." />
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start badge rounded-pill bg-success">12354.00$</span>
                                                                            <span className="float-end">
                                                                                <a href="#">Example</a>
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="card-title">Tesla Superchargers are high-speed charging stations strategically located along major highways and travel routes.
          These chargers are designed to provide rapid charging for Tesla vehicles, allowing drivers to quickly recharge
          their batteries during long journeys.</h5>
                                                                        <div className="d-grid gap-2 my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://c.ndtvimg.com/2023-03/62fsok28_tesla_625x300_06_March_23.jpg" className="card-img-top" alt="..." />
                                                                    <div className="label-top shadow-sm">Asus Rog</div>
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start badge rounded-pill bg-success">12354.00$</span>
                                                                            <span className="float-end">
                                                                                <a href="#" className="small text-muted">Reviews</a>
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="card-title">Tesla Superchargers are high-speed charging stations strategically located along major highways and travel routes.
          These chargers are designed to provide rapid charging for Tesla vehicles, allowing drivers to quickly recharge
          their batteries during long journeys.</h5>
                                                                        <div className="text-center my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                        <div className="clearfix mb-1">
                                                                            <span className="float-start">
                                                                                <i className="far fa-question-circle" />
                                                                            </span>
                                                                            <span className="float-end">
                                                                                <i className="fas fa-plus" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://cdn.intelligentcarleasing.com/blog/wp-content/uploads/2019/12/Supercharging_Tesla.jpg" className="card-img-top" alt="..." />
                                                                    <div className="label-top shadow-sm">DELL</div>
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start price-hp">12354.00$</span>
                                                                            <span className="float-end">
                                                                                <a className="text-muted small" href="#">Reviews</a>
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="card-title">Tesla Superchargers are high-speed charging stations strategically located along major highways and travel routes.
          These chargers are designed to provide rapid charging for Tesla vehicles, allowing drivers to quickly recharge
          their batteries during long journeys.</h5>
                                                                        <div className="text-center my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                        <div className="clearfix mb-1">
                                                                            <span className="float-start">
                                                                                <i className="far fa-question-circle" />
                                                                            </span>
                                                                            <span className="float-end">
                                                                                <i className="fas fa-plus" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://cdn.motor1.com/images/mgl/KPz1A/s3/tesla-model-3-charging.webp" className="card-img-top" alt="..." />
                                                                    <div className="label-top shadow-sm">DELL</div>
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start price-hp">12354.00$</span>
                                                                            <span className="float-end">
                                                                                <a className="text-muted small" href="#">Reviews</a>
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="card-title">  Tesla Destination Chargers are installed at various destinations such as hotels, restaurants, and shopping centers.
          These chargers are designed to provide convenience to Tesla owners while they go about their daily activities or
          while staying at specific locations.
          their batteries during long journeys.</h5>
                                                                        <div className="text-center my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                        <div className="clearfix mb-1">
                                                                            <span className="float-start">
                                                                                <i className="far fa-question-circle" />
                                                                            </span>
                                                                            <span className="float-end">
                                                                                <i className="fas fa-plus" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://www.coloradoboulevard.net/wp-content/uploads/2021/11/Tesla-charging-units-Photo-Special-to-ColoradoBlvd.net_.jpg" className="card-img-top" alt="..." />
                                                                    <div className="label-top shadow-sm">DELL</div>
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start price-hp">12354.00$</span>
                                                                            <span className="float-end">
                                                                                <a className="text-muted small" href="#">Reviews</a>
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="card-title">  Tesla Destination Chargers are installed at various destinations such as hotels, restaurants, and shopping centers.
          These chargers are designed to provide convenience to Tesla owners while they go about their daily activities or
          while staying at specific locations.</h5>
                                                                        <div className="text-center my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                        <div className="clearfix mb-1">
                                                                            <span className="float-start">
                                                                                <i className="far fa-question-circle" />
                                                                            </span>
                                                                            <span className="float-end">
                                                                                <i className="fas fa-plus" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://i0.wp.com/crownfmng.com/wp-content/uploads/2021/11/electric-car.jpg?w=930&ssl=1" className="card-img-top" alt="..." />
                                                                    <div className="label-top shadow-sm">DELL</div>
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start price-hp">12354.00$</span>
                                                                            <span className="float-end">
                                                                                <a className="text-muted small" href="#">Reviews</a>
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="card-title">  Tesla Destination Chargers are installed at various destinations such as hotels, restaurants, and shopping centers.
          These chargers are designed to provide convenience to Tesla owners while they go about their daily activities or
          while staying at specific locations.</h5>
                                                                        <div className="text-center my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                        <div className="clearfix mb-1">
                                                                            <span className="float-start">
                                                                                <i className="far fa-question-circle" />
                                                                            </span>
                                                                            <span className="float-end">
                                                                                <i className="fas fa-plus" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col">
                                                                <div className="card h-100 shadow-sm">
                                                                    <img src="https://www.uow.edu.au/assets/media-centre/Charge-Around-Australia-ANFF-Open-Day_Nov2022jpg.jpg" className="card-img-top" alt="..." />
                                                                    <div className="label-top shadow-sm">DELL</div>
                                                                    <div className="card-body">
                                                                        <div className="clearfix mb-3">
                                                                            <span className="float-start price-hp">12354.00$</span>
                                                                            <span className="float-end">
                                                                                <a className="text-muted small" href="#">Reviews</a>
                                                                            </span>
                                                                        </div>
                                                                        <h5 className="card-title">  Tesla Destination Chargers are installed at various destinations such as hotels, restaurants, and shopping centers.
          These chargers are designed to provide convenience to Tesla owners while they go about their daily activities or
          while staying at specific locations.</h5>
                                                                        <div className="text-center my-4">
                                                                            <a href="#" className="btn btn-warning">Select Charger</a>
                                                                        </div>
                                                                        <div className="clearfix mb-1">
                                                                            <span className="float-start">
                                                                                <i className="far fa-question-circle" />
                                                                            </span>
                                                                            <span className="float-end">
                                                                                <i className="fas fa-plus" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </main>


                                                <div className="ui bottom attached tab segment active" data-tab="nine">
                                                    <p className="info-selected-pay">If you're looking for an investment that aligns with innovation, sustainability,
                                                     and the future of transportation, Tesla chargers are an excellent choice. 
                                                    Let's take this exciting opportunity further and explore how it could fit into your investment portfolio.</p>
                                                    <div className="mini-withdraw-form" style={{ display: 'inline' }}>
                                                        <div className="input-amount-wrap">
                                                            <form >
                                                                <div id="error" style={{ fontSize: 'small', color: '#FF0000' }} />
                                                                <label>
                                                                    <input type="hidden" required name="method" defaultValue="Bitcoin" size={20} style={{ minWidth: '150px' }} />
                                                                    <span>investment Amount: </span>
                                                                    <span>USD</span>
                                                                    <input type="text" required name="w_amount" onChange={(e) => { setamount(e.target.value) }} placeholder={0.00} size={20} style={{ minWidth: '150px' }} id="amount" onkeypress="javascript:return isNumber(event)" /></label>
                                                                <div style={{ height: '10px' }} />
                                                                <label>
                                                                    <span>No Of units To Purchase: </span>
                                                                    <input type="text" required name="w_address" onChange={(e) => { setwallet(e.target.value) }} defaultValue size={40} style={{ minWidth: '200px' }} />
                                                                </label>
                                                                <button className="ui button primal" type="button" onClick={Confirmwithdrawal} name="wit_btn" id="wit_btn">Next <span className="arrow-next">→</span></button>
                                                            </form>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p className="after-note">
                                                <b>Please note:</b>
                                                before approving a withdrawal request, you may be required to submit proof
                                                of identity and address of the requester. Withdrawal fees will be
                                                applied, based on type of trading account and acceptable withdrawal
                                                method. Withdrawals are normaly processed, using the same method as
                                                deposit was done. For security reasons, withdrawal requests to ewallets,
                                                 bank and creditcard accounts, not belonging to a trading account owner are denied. Please refer to terms and conditions for more information.</p> */}
                                        </div>
                                    </div>
                                </section>
                                <style dangerouslySetInnerHTML={{ __html: " \np1 { \n  text-align: center; \n  font-size: 40px; \n}\np2 { \n  text-align: center; \n  font-size: 30px; \n} \n" }} />
                                <style className="ng-scope" dangerouslySetInnerHTML={{ __html: "\n    .md-dialog-content {\n        color: #333;\n    }\n    .wd-warn {\n        font-size: 14px;\n        font-weight: 400;\n        color:#fff;\n    }\n    .wd-pending {\n        margin: 40px 0;\n    }\n" }} /></div>
                        </div></div>
                    </div>
                </div>
                
                <div className="language langBox" style={{}}>
                <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{ height: '50px', width: 'auto' }} />
                <div id="google_translate_element" />
              </div>
              
                <footer className="footer">
                    <div className="row clearfix">
                        <div className="float-left">
                            <span className="copy">© 2022 <span data-company>Elite-protrade.com</span>. <span data-company-address>71 Cherry Court avenue London,  United Kingdom</span></span> <a href="/en/policies">Privacy policy</a>
                        </div>
                        <div className="float-right">
                            <ul className="soc-list">
                                <li>Follow us</li>
                                {/*<li>
                    	<div class="footer-media">
		                    <div class="media-icons">
		                        <a href="https://www.facebook.com/Elite-protrade.com"><span class="icon-mso-fb"></span></a>
		                    </div>
		                </div>
                    </li>*/}
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-instagram" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-twitter-square" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                {/*<li><a href="https://www.linkedin.com/company/Elite-protrade.com" class="soc_li"></a></li> 
                    <li><a href="https://twitter.com/Elite-protrade.comCom" class="soc_tw"></a></li>
                    <li><a href="https://www.facebook.com/Elite-protrade.comCom" class="soc_fb"></a></li>*/}
                                {/*<li><a href="" class="soc_yt"></a></li>*/}
                            </ul>
                        </div>
                    </div>
                </footer>	</div>
            {/* Yandex.Metrika counter */}
            <noscript aria-hidden="false">&lt;div&gt;&lt;img src="https://mc.yandex.ru/watch/38379630" style="position:absolute; left:-9999px;" alt="" /&gt;&lt;/div&gt;</noscript>
            {/* /Yandex.Metrika counter */}
            <noscript aria-hidden="false">
                &lt;div style="display:inline;"&gt;
                &lt;img height="1" width="1" style="border-style:none;" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1057644682/?guid=ON&amp;amp;script=0"/&gt;
                &lt;/div&gt;
            </noscript>
            {/* Global site tag (gtag.js) - Google AdWords: 824992907 */}
            {/*StartofTawk.toScript*/}
            {/*End of Tawk.to Script*/}
            {
                loading && (
                    <div class="preloader js-preloader">
                        <div class="loader loader-inner-1">
                            <div class="loader loader-inner-2">
                                <div class="loader loader-inner-3">
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Teslachargers