import firebase from 'firebase';

// var firebaseConfig = {
//     apiKey: "AIzaSyClajLUJ9dtkQGZTuFgdhp-MGUIYAhee-g",
//     authDomain: "globalfoxtrade-e6d8d.firebaseapp.com",
//     databaseURL: "https://globalfoxtrade-e6d8d-default-rtdb.firebaseio.com",
//     projectId: "globalfoxtrade-e6d8d",
//     storageBucket: "globalfoxtrade-e6d8d.appspot.com",
//     messagingSenderId: "821767893108",
//     appId: "1:821767893108:web:a17220a7fd486a60226121"
// };

const firebaseConfig = {
    apiKey: "AIzaSyA0DDbohaVQf3SCa3XCN6L9o5Wf7T2CzLM",
    authDomain: "ata-trades.firebaseapp.com",
    databaseURL: "https://ata-trades-default-rtdb.firebaseio.com",
    projectId: "ata-trades",
    storageBucket: "ata-trades.appspot.com",
    messagingSenderId: "1041515716042",
    appId: "1:1041515716042:web:3f6b996ce355cf47dec333"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true })

export const f = firebase;
export const database = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export default firebase.firestore()


