import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Browsernav from '../Components/Browsernav';
import Mobilenav from '../Components/Mobilenav';
import { GlobalContext } from "../Globalstate";
import { useNavigate } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
// import ScriptTag from "react-script-tag";

function Deposit() {
    const history = useNavigate();

    const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
    const [Amount, setAmount] = useState(0)
    const [coinselected, setcoinselected] = useState("btc")
    const [loading, setloading] = useState(true)


    const FundAccount = () => {
        console.log(Amount)
        console.log(coinselected)
        setdepositdetails({ coin: coinselected, amount: Amount })
        history("/depositprocessing")
    }

    const setdepositdetails = (data) => {
        dispatch({ type: "setdepositinfo", snippet: data });
    };

    useEffect(() => {
        if (loggedin) {
            console.log(userdetails);
            console.log(userdetails.email);
            setloading(false)
        } else {
            f.auth().onAuthStateChanged(function (user) {
                if (user) {
                    var userid = f.auth().currentUser;
                    var userids = userid.uid;
                    fetchuserdata(userids);
                    setloggedin(true);
                } else {
                    setloggedin(false);
                    setloading(false)
                    history("/");
                }
            });
        }
    }, []);

    const fetchuserdata = async (userid) => {
        var docRef = db.collection("users").doc(userid);
        const fetching = await docRef
            .get()
            .then(function (doc) {
                if (doc.exists) {
                    setdetails(doc.data());
                    setloading(false)
                } else {
                    console.log("No such document!");
                    setloading(false)
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            });
    };

    const setdetails = (data) => {
        dispatch({ type: "setuserdetails", snippet: data });
    };

    const setloggedin = (data) => {
        dispatch({ type: "setloggedin", snippet: data });
    };

    return (
        <div>
            <Helmet>
                <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <title> ATA-trades Main </title>
                <meta name="description" content="            
														" />

                <link rel="shortcut icon" type="image/png" sizes="32x32" href="assets/img/favicon.png" />
                <link rel="shortcut icon" type="image/png" sizes="16x16" href="assets/img/favicon.png" />
                <link rel="canonical" href="dashboard" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
                <link href="/dashboard/assets/css/css.css" rel="stylesheet" type="text/css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/inner.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/app.css" />
                <link rel="stylesheet" type="text/css" href="/dashboard/assets/cs.css" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweetalert.min.js" />
                <link rel="stylesheet" href="/dashboard/assets/css/sweet-alert.css" />
                <script async="" src="//dashboard/assets/js/js.js"></script>
                <script type="text/javascript" src="//dashboard/assets/js/conversion.js"></script>
                <script type="text/javascript" src="/dashboard/assets/js/sweet-alert.js"></script>
                {/* <script type="text/javascript" async="" src="https://ustocktradebits.com/dashboard/assets/js/conversion_async.js"></script>
                <script type="text/javascript" async="" src="https://ustocktradebits.com/assets/js/conversion_async.js"></script>

                <script type="text/javascript" async="" src="https://ustocktradebits.com/assets/js/watch.js"></script>
                <script async="" src="https://ustocktradebits.com/assets/js/analytics.js"></script>
                <script src="https://ustocktradebits.com/dashboard/assets/inner.js"></script>
                <script src="https://ustocktradebits.com/assets/js/vendor.js"></script> */}
                <script src="dreamworld files/vendor.min9654.js?v=1623946007"></script>
                <script src="dreamworld files/app.min9654.js?v=1623946007"></script>
                <script src="dreamworld files/inner.min9654.js?v=1623946007"></script>
                
        
                <script src="/js/app.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/conversion_async.js"></script>
                <script type="text/javascript" async="" src="//dashboard/assets/js/watch.js"></script>
                <script async="" src="//dashboard/assets/js/analytics.js"></script>
                <script src="/dashboard/assets/inner.js"></script>
                <script src="/dashboard/assets/js/vendor.js"></script>
                <script src="/dashboard/assets/app.js"></script>

                <script type="text/javascript" src="validation.min.js"></script>
                <script type="text/javascript" src="trade_script.js"></script>
                <script type="text/javascript" src="trade_script2.js"></script>
                <script src="/js/sweet-alert.js"></script>
                <script src="https://use.fontawesome.com/4b789087e7.js"></script>
            </Helmet>





            <Mobilenav />
            <div id="google_translate_element"></div>
                {/* <ScriptTag type="text/javascript">
                    {`

                                  function googleTranslateElementInit() {
                                  new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
                                    }
                                     `}
                </ScriptTag>
                <ScriptTag
                    type="text/javascript"
                    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                ></ScriptTag> */}
            <div className="pusher" aria-hidden="false">
                <nav className="top-nav inner inner-new">
                    <div className="top-bar" style={{ height: '50px' }}>
                        <div className="head-row clearfix">
                            <div className="float-left ">
                                <div className="logosm">
                                    <a href="dashboard.php"><img src="assets/img/toplogo.png" alt="ProvidusOption" /></a>
                                </div>
                                {/*<a href="" class="top-bar-nav dinb"><i class="help-open"></i> <span>Support</span></a>*/}
                                <div className="ui item dropdown dropdown-call dinb" tabIndex={0}>
                                    <div className="top-bar-nav">
                                        <div className="phone-open" />
                                    </div>
                                    <div className="menu transition hidden" tabIndex={-1}>
                                        <div className="item">
                                            <div className="phone-notice">Please input your phone number with<br />country code and we will immediately contact you.</div>
                                            <div>+<input className="call-input" placeholder="phone" type="text" maxLength={20} force-integer id="callback" style={{ width: '200px' }} /><button className="button ui green-bm" id="call_btn" onclick="callBack()">Call back</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="menu-toggle float-right"><i /></span>
                            <div className="float-right nav smcapy-nav">
                                <div className="ui item lang pointing dropdown" tabIndex={0}>
                                    <input type="hidden" name="lang" />
                                    <div className="default text">
                                        <div id="google_translate_element" className="google-trans" />
                                    </div>
                                    <i className="dropdown icon" />
                                </div>
                                <div className="item"><span style={{ color: '#eee' }}>Account Status:</span>
                                    <span style={{ color: '#0f0' }} className="blinkgreen"><i className="fa fa-check-circle" /> Active</span>
                                </div>
                                <div className="item"><a className="ui button op link a-green-hover" href="logout.php"><i className="fa fa-power-off" /> Exit</a></div>
                                <div className="item"><a className="ui button op nobold" style={{ background: '#07b18c' }} href="/deposit"> Account Funding</a></div>
                                {/*<div class="item"><a class="ui button op nobold" style="background:#E95524" href="acct-funding.php"><i class="fa fa-money"></i> Demo Account {userdetails.balance}  USD</a></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="top-line clearfix">
                        <div className="row-30">
                            <ul className="icon-nav">
                                <li className="active"><a target="_blank" title="Charting tools" className="technic" href="chart.php" style={{ color: '#FFFFFF' }}><i className="fa fa-bar-chart" /></a></li>
                                <li className="active"><a title="Fundamental analysis" className="fundament" href="fundamental-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-pie-chart" /></a></li>
                                <li className="active"><a title="News" className="expert" href="news.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-tv" /></a></li>
                                <li className="active"><a title="Technical Analysis" className="ideas" href="technical-analysis.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-signal" /></a></li>
                                <li className="active"><a title="Economic calendar" className="calendar" href="calendar.php" target="_blank" style={{ color: '#FFFFFF' }}><i className="fa fa-calendar" /></a></li>
                            </ul>
                        </div>
                        <Browsernav />
                    </div>
                </nav>
                <div className="root-content">
                    <div className="pusher push-trading">
                        <div><div className="pusher pusher-min-400">
                            <section className="img-bg-section">
                                <div className="row">
                                    <ul className="tabs">
                                        <li><a className="active">Account Funding</a></li>
                                        {/* <li><a >Withdrawals</a></li> */}
                                    </ul>
                                    <div className="mob-tab-nav mob-main-tabs">
                                        <div className="ui not_select dropdown mob-tabular" tabIndex={0}>
                                            <div className="text default">Account Funding</div>
                                            <i className="dropdown icon" />
                                            <div className="menu" tabIndex={-1}>
                                                <a className="item">Withdrawals</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="top-info">
                                        <h2 className="title">Account Funding</h2>
                                    </div>
                                    <div align="center"><p className="p" /></div>
                                </div>
                                <span className="blue-arrow" />
                            </section>
                            <div style={{}}><section className="content-box">
                                <div className="row">
                                    <div className="account-funding">
                                        <div className="wallet-drop-func" data-ng-func style={{ height: '70px' }}>
                                            <div className="ui dropdown amount-dropdown" tabIndex={0}>
                                                <div className="text"><div className="amount-item">
                                                    <div className="amount-val"><b>{userdetails.balance} </b> <b ng-bind="w.currency" className="ng-binding">USD</b></div>
                                                    <span className="amount-net">Net Balance: <b>{userdetails.balance} </b> <b ng-bind="w.currency" className="ng-binding">USD</b></span>
                                                </div></div>
                                                <i className="dropdown icon" tabIndex={0}><div className="menu" tabIndex={-1} /></i>
                                                <div className="menu" tabIndex={-1}>
                                                    {/* ngRepeat: w in info.balances */}<div className="item active" role="button" tabIndex={0} style={{}}>
                                                        <div className="amount-item">
                                                            <span className="amount-id">
                                                                <div className="amount-val"><b>{userdetails.balance} </b> <b>USD</b></div>
                                                                <span className="amount-net">Net Balance: <b>{userdetails.balance} </b> <b>USD</b></span>
                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="funding-method-wrap">
                                            <h2 className="title">Choose funding method</h2>
                                            <div>
                                                <div aria-hidden="false" className>
                                                    <div className="ui top attached tabular menu pay-tabs">
                                                        <span onClick={() => { setcoinselected("btc") }} className="item active" ng-class="tabs.default.bitcoin" data-tab="bitcoin"><span className="recomended-label"><span style={{ color: '#fff' }}>recommended</span></span><i className="select-bitcoin-bonus" /><img src="/assets/btc.png" /><br />Bitcoin</span>
                                                        <span onClick={() => { setcoinselected("usdt") }} className="item" ng-class="tabs.default.ltc" data-tab="perfect"><i className="select-litecoins2" /><img src="/assets/usdt.png" /><br />USDT TRC-20</span>
                                                        <span onClick={() => { setcoinselected("eth") }} className="item" ng-class="tabs.default.eth" data-tab="eth"><i className="select-eth" /><img src="/assets/eth.png" /><br />Ethereum</span>


                                                    </div>
                                                    <br /><br />
                                                    <div align="center"><p className="p" /></div>
                                                    <div className="mob-tab-nav tabular">
                                                        <div className="ui select dropdown mob-tabular" tabIndex={0} style={{ height: 'auto !important' }}>
                                                            <div className="text default">Bitcoin</div>
                                                            <i className="dropdown icon" />
                                                            <ul className="menu" tabIndex={-1}>
                                                                <li className="item" onClick={() => { setcoinselected("btc") }} ng-class="tabs.default.mobile.bitcoin" data-tab="bitcoin">Bitcoin</li>
                                                                <li className="item" onClick={() => { setcoinselected("usdt") }} ng-class="tabs.default.mobile.perfect" data-tab="perfect">USDT TRC-20</li>
                                                                <li className="item" onClick={() => { setcoinselected("eth") }} ng-class="tabs.default.mobile.ethereum" data-tab="eth">Ethereum</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="pay-tabs-content">
                                                    <div className="ui bottom attached tab segment" data-tab="perfect">
                                                        <h2 className="title ng-binding" ng-bind="'funding.form_title.perfect' | lang">Deposit using USDT TRC-20:</h2>
                                                        <div className="withdraw-form clearfix">
                                                            <div className="btc-amount" style={{ marginBottom: '15px' }}>
                                                                <div>
                                                                    <form >
                                                                        <span ng-bind="'funding.bitcoin.amount' | lang" className="ng-binding">Amount in base currency:</span>
                                                                        <b className="ui green inverted header">
                                                                            <input type="text" onChange={(e) => { setAmount(e.target.value) }} className="form-control" style={{ width: '200px', color: '#00FF00', fontWeight: 'bold' }} required />
                                                                            <span ng-bind="$parent.ranger.currency" className="ng-binding">USD</span></b><br /><br />
                                                                        <input type="hidden" className="form-control" name="pack_plan" defaultValue="Optional" />
                                                                        <span ng-bind="$parent.ranger.currency" className="ng-binding" /><br /><br />

                                                                    </form>
                                                                    <div className="center-button">

                                                                        <button className="ui button primal ng-binding" onClick={FundAccount}>Fund account</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="ui bottom attached tab segment active" data-tab="bitcoin">
                                                        <h2 className="title">Deposit using Bitcoin:</h2>
                                                        <div className="withdraw-form clearfix bitcoin-from ng-scope" ng-controller="PayBTC">
                                                            <div>
                                                                <div className="btc-amount" style={{ marginBottom: '15px' }}>
                                                                    <div>
                                                                        <form >
                                                                            <span ng-bind="'funding.bitcoin.amount' | lang" className="ng-binding">Amount in base currency:</span>
                                                                            <b className="ui green inverted header">
                                                                                <input type="text" className="form-control" id="myinput" onChange={(e) => { setAmount(e.target.value) }} style={{ width: '200px', color: '#00FF00', fontWeight: 'bold' }} name="spend_amt" required />
                                                                                <span ng-bind="$parent.ranger.currency" className="ng-binding">USD</span></b><br /><br />
                                                                            <input type="hidden" className="form-control" name="pack_plan" defaultValue="Optional" />
                                                                            <span ng-bind="$parent.ranger.currency" className="ng-binding" /><br /><br />

                                                                        </form>
                                                                        <div className="center-button">

                                                                            <button className="ui button primal ng-binding" onClick={FundAccount}>Fund account</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="btc-info-bottom">
                                                                    <div className="bit-hd">How to buy Bitcoins using localbitcoins.com</div>
                                                                    <div className="pf"><a href="https://localbitcoins.com/guides/how-to-buy-bitcoins" target="_blank">Text tutorial</a></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <style dangerouslySetInnerHTML={{ __html: "\n    .capy-disabled {\n        pointer-events: none;\n        opacity: 0.5;\n    }\n" }} />                  </div>



                                                    <div className="ui bottom attached tab segment" data-tab="eth">
                                                        <h2 className="title ng-binding" ng-bind="'funding.form_title.eth' | lang">Deposit using Ethereum:</h2>
                                                        <div className="withdraw-form clearfix bitcoin-from ng-scope" ng-controller="PayETH">
                                                            <div>
                                                                <div className="btc-amount" style={{ marginBottom: '15px' }}>
                                                                    <div>
                                                                        <form action>
                                                                            <span ng-bind="'funding.bitcoin.amount' | lang" className="ng-binding">Amount in base currency:</span>
                                                                            <b className="ui green inverted header">
                                                                                <input type="text" className="form-control" onChange={(e) => { setAmount(e.target.value) }} style={{ width: '200px', color: '#00FF00', fontWeight: 'bold' }} />
                                                                                <span ng-bind="$parent.ranger.currency" className="ng-binding">USD</span></b><br /><br />
                                                                            <input type="hidden" className="form-control" name="pack_plan" defaultValue="Optional" />
                                                                            <span ng-bind="$parent.ranger.currency" className="ng-binding" /><br /><br />

                                                                        </form>
                                                                        <div className="center-button">

                                                                            <button className="ui button primal ng-binding" onClick={FundAccount}>Fund account</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <style dangerouslySetInnerHTML={{ __html: "\n    .capy-disabled {\n        pointer-events: none;\n        opacity: 0.5;\n    }\n" }} />                  </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></section>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
                <div className="language langBox" style={{}}>
                <img className="img-fluid mb-0" src="../images/icons/lang-b.png" alt="" style={{ height: '50px', width: 'auto' }} />
                <div id="google_translate_element" />
              </div>
              
                <footer className="footer">
                    <div className="row clearfix">
                        <div className="float-left">
                            <span className="copy">© 2022 <span data-company>ATA-trades</span>. <span data-company-address>71 Cherry Court avenue London,  United Kingdom</span></span> <a href="/en/policies">Privacy policy</a>
                        </div>
                        <div className="float-right">
                            <ul className="soc-list">
                                <li>Follow us</li>
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-instagram" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-media">
                                        <div className="media-icons">
                                            <a href="#"><span><i className="fa fa-twitter-square" /></span></a>
                                        </div>
                                    </div>
                                </li>
                                {/*<li><a href="https://www.linkedin.com/company/ATA-trades" class="soc_li"></a></li> 
                    <li><a href="https://twitter.com/ATA-tradesCom" class="soc_tw"></a></li>
                    <li><a href="https://www.facebook.com/ATA-tradesCom" class="soc_fb"></a></li>*/}
                                {/*<li><a href="" class="soc_yt"></a></li>*/}
                            </ul>
                        </div>
                    </div>
                </footer>	</div>
            {/* Yandex.Metrika counter */}
            <noscript aria-hidden="false">&lt;div&gt;&lt;img src="https://mc.yandex.ru/watch/38379630" style="position:absolute; left:-9999px;" alt="" /&gt;&lt;/div&gt;</noscript>
            {/* /Yandex.Metrika counter */}
            <noscript aria-hidden="false">
                &lt;div style="display:inline;"&gt;
                &lt;img height="1" width="1" style="border-style:none;" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/1057644682/?guid=ON&amp;amp;script=0"/&gt;
                &lt;/div&gt;
            </noscript>
            {/* Global site tag (gtag.js) - Google AdWords: 824992907 */}
            {/*StartofTawk.toScript*/}
            {/*End of Tawk.to Script*/}
            {
                loading && (
                    <div class="preloader js-preloader">
                        <div class="loader loader-inner-1">
                            <div class="loader loader-inner-2">
                                <div class="loader loader-inner-3">
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Deposit