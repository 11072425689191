export const CryptoPairs = [
    { symbol: 'ETH/BTC', tradingsymbol: "ETHBTC", ist: "ETH", sec: "BTC", idist: "ethereum", idsec: "bitcoin" },
    { symbol: 'DOGE/BTC', tradingsymbol: "DOGEBTC", ist: "DOGE", sec: "BTC", idist: "dogecoin", idsec: "bitcoin" },
    { symbol: 'BNB/BTC', tradingsymbol: "BNBBTC", ist: "BNB", sec: "BTC", idist: "binance", idsec: "bitcoin" },
    { symbol: 'BTT/BTC', tradingsymbol: "BTTBTC", ist: "BTT", sec: "BTC", idist: "bittorrent", idsec: "bitcoin" },
    { symbol: 'XMR/BTC', tradingsymbol: "XMRBTC", ist: "XMR", sec: "BTC", idist: "monero", idsec: "bitcoin" },
    { symbol: 'XMR/ETH', tradingsymbol: "XMRETH", ist: "XMR", sec: "ETH", idist: "monero", idsec: "ethereum" },
    { symbol: 'NEO/BTC', tradingsymbol: "NEOBTC", ist: "NEO", sec: "BTC", idist: "neo", idsec: "bitcoin" },
    { symbol: 'NEO/ETH', tradingsymbol: "NEOETH", ist: "NEO", sec: "ETH", idist: "neo", idsec: "ethereum" },
    { symbol: 'ADA/BTC', tradingsymbol: "ADABTC", ist: "ADA", sec: "BTC", idist: "cardano", idsec: "bitcoin" },
    { symbol: 'ADA/ETH', tradingsymbol: "ADAETH", ist: "ADA", sec: "ETH", idist: "cardano", idsec: "ethereum" },
    { symbol: 'MTV/BTC', tradingsymbol: "mtvBTC", ist: "mtv", sec: "BTC", idist: "multivac", idsec: "bitcoin" },
    { symbol: 'MTC/ETH', tradingsymbol: "MTCETH", ist: "MTC", sec: "ETH", idist: "multivac", idsec: "ethereum" },
    { symbol: 'XRP/BTC', tradingsymbol: "XRPBTC", ist: "XRP", sec: "BTC", idist: "xrp", idsec: "bitcoin" },
    { symbol: 'TRX/BTC', tradingsymbol: "TRXBTC", ist: "TRX", sec: "BTC", idist: "tron", idsec: "bitcoin" },
    { symbol: 'BCH/BTC', tradingsymbol: "BCHBTC", ist: "BCH", sec: "BTC", idist: "bitcoin-cash", idsec: "bitcoin" },
    { symbol: 'LTC/BTC', tradingsymbol: "LTCBTC", ist: "LTC", sec: "BTC", idist: "litecoin", idsec: "bitcoin" },
    { symbol: 'BNB/ETH', tradingsymbol: "BNBETH", ist: "BNB", sec: "ETH", idist: "binance", idsec: "ethereum" },
    { symbol: 'DOGE/ETH', tradingsymbol: "DOGEETH", ist: "DOGE", sec: "ETH", idist: "dogecoin", idsec: "ethereum" },
    { symbol: 'BNB/ETH', tradingsymbol: "BNBETH", ist: "BNB", sec: "ETH", idist: "binance", idsec: "ethereum" },
    { symbol: 'BTT/ETH', tradingsymbol: "BTTETH", ist: "BTT", sec: "ETH", idist: "bittorrent", idsec: "ethereum" },
    { symbol: 'XRP/ETH', tradingsymbol: "XRPETH", ist: "XRP", sec: "ETH", idist: "xrp", idsec: "ethereum" },
    { symbol: 'TRX/ETH', tradingsymbol: "TRXETH", ist: "TRX", sec: "ETH", idist: "tron", idsec: "ethereum" },
    { symbol: 'BCH/ETH', tradingsymbol: "BCHETH", ist: "BCH", sec: "ETH", idist: "bitcoin-cash", idsec: "ethereum" },
    { symbol: 'LTC/ETH', tradingsymbol: "LTCETH", ist: "LTC", sec: "ETH", idist: "litecoin", idsec: "ethereum" },
];
// export const CryptoPairs = [
//     { symbol: 'ETH/BTC', tradingsymbol: "ETHBTC", ist: "ETH", sec: "BTC", idist: "ethereum", idsec: "bitcoin" },
//     { symbol: 'DOGE/BTC', tradingsymbol: "DOGEBTC", ist: "DOGE", sec: "BTC", idist: "dogecoin", idsec: "bitcoin" },
//     { symbol: 'BNB/BTC', tradingsymbol: "BNBBTC", ist: "BNB", sec: "BTC", idist: "binancecoin", idsec: "bitcoin" },
//     { symbol: 'BTT/BTC', tradingsymbol: "BTTBTC", ist: "BTT", sec: "BTC", idist: "bittorrent-2", idsec: "bitcoin" },
//     { symbol: 'XMR/BTC', tradingsymbol: "XMRBTC", ist: "XMR", sec: "BTC", idist: "monero", idsec: "bitcoin" },
//     { symbol: 'XMR/ETH', tradingsymbol: "XMRETH", ist: "XMR", sec: "ETH", idist: "monero", idsec: "ethereum" },
//     { symbol: 'NEO/BTC', tradingsymbol: "NEOBTC", ist: "NEO", sec: "BTC", idist: "neo", idsec: "bitcoin" },
//     { symbol: 'NEO/ETH', tradingsymbol: "NEOETH", ist: "NEO", sec: "ETH", idist: "neo", idsec: "ethereum" },
//     { symbol: 'ADA/BTC', tradingsymbol: "ADABTC", ist: "ADA", sec: "BTC", idist: "cardano", idsec: "bitcoin" },
//     { symbol: 'ADA/ETH', tradingsymbol: "ADAETH", ist: "ADA", sec: "ETH", idist: "cardano", idsec: "ethereum" },
//     { symbol: 'MTV/BTC', tradingsymbol: "mtvBTC", ist: "mtv", sec: "BTC", idist: "multivac", idsec: "bitcoin" },
//     { symbol: 'MTC/ETH', tradingsymbol: "MTCETH", ist: "MTC", sec: "ETH", idist: "multivac", idsec: "ethereum" },
//     { symbol: 'XRP/BTC', tradingsymbol: "XRPBTC", ist: "XRP", sec: "BTC", idist: "ripple", idsec: "bitcoin" },
//     { symbol: 'TRX/BTC', tradingsymbol: "TRXBTC", ist: "TRX", sec: "BTC", idist: "tron", idsec: "bitcoin" },
//     { symbol: 'BCH/BTC', tradingsymbol: "BCHBTC", ist: "BCH", sec: "BTC", idist: "bitcoin-cash", idsec: "bitcoin" },
//     { symbol: 'LTC/BTC', tradingsymbol: "LTCBTC", ist: "LTC", sec: "BTC", idist: "litecoin", idsec: "bitcoin" },
//     { symbol: 'BNB/ETH', tradingsymbol: "BNBETH", ist: "BNB", sec: "ETH", idist: "binancecoin", idsec: "ethereum" },
//     { symbol: 'DOGE/ETH', tradingsymbol: "DOGEETH", ist: "DOGE", sec: "ETH", idist: "dogecoin", idsec: "ethereum" },
//     { symbol: 'BNB/ETH', tradingsymbol: "BNBETH", ist: "BNB", sec: "ETH", idist: "binancecoin", idsec: "ethereum" },
//     { symbol: 'BTT/ETH', tradingsymbol: "BTTETH", ist: "BTT", sec: "ETH", idist: "bittorrent-2", idsec: "ethereum" },
//     { symbol: 'XRP/ETH', tradingsymbol: "XRPETH", ist: "XRP", sec: "ETH", idist: "ripple", idsec: "ethereum" },
//     { symbol: 'TRX/ETH', tradingsymbol: "TRXETH", ist: "TRX", sec: "ETH", idist: "tron", idsec: "ethereum" },
//     { symbol: 'BCH/ETH', tradingsymbol: "BCHETH", ist: "BCH", sec: "ETH", idist: "bitcoin-cash", idsec: "ethereum" },
//     { symbol: 'LTC/ETH', tradingsymbol: "LTCETH", ist: "LTC", sec: "ETH", idist: "litecoin", idsec: "ethereum" },
// ];


export const Forexpairs = [{ symbol: "USD/CAD", fromsymbol: "USD", tosymbol: "CAD" },
{ symbol: "EUR/USD", fromsymbol: "EUR", tosymbol: "USD" }, { symbol: "USD/CHF", fromsymbol: "USD", tosymbol: "CHF" },
{ symbol: "GBP/USD", fromsymbol: "GBP", tosymbol: "USD" }, { symbol: "NZD/USD", fromsymbol: "NZD", tosymbol: "USD" },
{ symbol: "AUD/USD", fromsymbol: "AUD", tosymbol: "USD" }, { symbol: "EUR/CAD", fromsymbol: "EUR", tosymbol: "CAD" },
{ symbol: "EUR/AUD", fromsymbol: "EUR", tosymbol: "AUD" }, { symbol: "EUR/JPY", fromsymbol: "EUR", tosymbol: "JPY" },
{ symbol: "GBP/CHF", fromsymbol: "GBP", tosymbol: "CHF" }, { symbol: "GBP/JPY", fromsymbol: "GBP", tosymbol: "JPY" },
{ symbol: "CHF/JPY", fromsymbol: "CHF", tosymbol: "JPY" }, { symbol: "AUD/JPY", fromsymbol: "AUD", tosymbol: "JPY" },
{ symbol: "AUD/NZD", fromsymbol: "AUD", tosymbol: "NZD" }
]

export const Stockpairs = [{ symbol: "Google Inc.", name: "GOOG" }, { symbol: "Google Inc.", name: "GOOG" },
{ symbol: "Intel Corporation", name: "INTC" }, { symbol: "Coca-Cola Company (The)", name: "KO" },
{ symbol: "Microsoft Corporation", name: "MSFT" }, { symbol: "Apple Computer, Inc.", name: "APPL" },
{ symbol: "eBay Inc.", name: "EBAY" }, { symbol: "Dell Inc..", name: "DELL" }, { symbol: "Oracle Corporation", name: "ORCL" },
{ symbol: "Walt Disney Company (The)", name: "DIS" }, { symbol: "Unilever PLC", name: "UL" },
{ symbol: "Amazon.inc", name: "AMZN" }, { symbol: "TESLA INC", name: "TSLA" }, { symbol: "NETFLIX INC", name: "NFLX" }]